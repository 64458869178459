:root {
  --body-bold-font-family: "SF Pro Text", Helvetica;
  --body-bold-font-size: 17px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 400;
  --body-bold-letter-spacing: -0.40799999237060547px;
  --body-bold-line-height: 22px;
  --content-bold-font-family: "Aeonik TRIAL", Helvetica;
  --content-bold-font-size: 16px;
  --content-bold-font-style: normal;
  --content-bold-font-weight: 700;
  --content-bold-letter-spacing: 0.32px;
  --content-bold-line-height: normal;
  --content-regular-font-family: "ABeeZee", Helvetica;
  --content-regular-font-size: 16px;
  --content-regular-font-style: normal;
  --content-regular-font-weight: 400;
  --content-regular-letter-spacing: 0.32px;
  --content-regular-line-height: normal;
  --content-title-bold-font-family: "Aeonik TRIAL", Helvetica;
  --content-title-bold-font-size: 20px;
  --content-title-bold-font-style: normal;
  --content-title-bold-font-weight: 400;
  --content-title-bold-letter-spacing: 0px;
  --content-title-bold-line-height: normal;
  --danger: rgba(250, 89, 89, 1);
  --defaultsystembluedark: rgba(10, 132, 255, 1);
  --defaultsystembluelight: rgba(0, 122, 255, 1);
  --defaultsystemgreendark: rgba(48, 209, 88, 1);
  --defaultsystemgreenlight: rgba(52, 199, 89, 1);
  --defaultsystemorangedark: rgba(255, 159, 10, 1);
  --defaultsystemorangelight: rgba(255, 149, 0, 1);
  --h1-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h1-bold-font-size: 110px;
  --h1-bold-font-style: normal;
  --h1-bold-font-weight: 700;
  --h1-bold-letter-spacing: 0px;
  --h1-bold-line-height: normal;
  --h2-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h2-bold-font-size: 90px;
  --h2-bold-font-style: normal;
  --h2-bold-font-weight: 700;
  --h2-bold-letter-spacing: 0px;
  --h2-bold-line-height: normal;
  --h3-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h3-bold-font-size: 60px;
  --h3-bold-font-style: normal;
  --h3-bold-font-weight: 700;
  --h3-bold-letter-spacing: 0px;
  --h3-bold-line-height: normal;
  --h4-bold-font-family: "Aeonik TRIAL", Helvetica;
  --h4-bold-font-size: 40px;
  --h4-bold-font-style: normal;
  --h4-bold-font-weight: 700;
  --h4-bold-letter-spacing: 0px;
  --h4-bold-line-height: normal;
  --label-colordarkprimary: rgba(255, 255, 255, 1);
  --label-colorlightprimary: rgba(0, 0, 0, 1);
  --mobile-10px-font-family: "ABeeZee", Helvetica;
  --mobile-10px-font-size: 10px;
  --mobile-10px-font-style: italic;
  --mobile-10px-font-weight: 400;
  --mobile-10px-letter-spacing: 0px;
  --mobile-10px-line-height: normal;
  --note-regular-font-family: "ABeeZee", Helvetica;
  --note-regular-font-size: 14px;
  --note-regular-font-style: normal;
  --note-regular-font-weight: 400;
  --note-regular-letter-spacing: 0px;
  --note-regular-line-height: normal;
  --notes-bold-font-family: "Aeonik TRIAL", Helvetica;
  --notes-bold-font-size: 14px;
  --notes-bold-font-style: normal;
  --notes-bold-font-weight: 700;
  --notes-bold-letter-spacing: 0px;
  --notes-bold-line-height: normal;
  --primary-100: rgba(252, 246, 218, 1);
  --primary-500main: rgba(255, 186, 21, 1);
  --primary-600: rgba(255, 153, 0, 1);
  --secondary-300: rgba(199, 224, 255, 1);
  --small-title-bold-font-family: "Aeonik TRIAL", Helvetica;
  --small-title-bold-font-size: 28px;
  --small-title-bold-font-style: normal;
  --small-title-bold-font-weight: 700;
  --small-title-bold-letter-spacing: 0px;
  --small-title-bold-line-height: normal;
  --small-title-regular-font-family: "ABeeZee", Helvetica;
  --small-title-regular-font-size: 28px;
  --small-title-regular-font-style: normal;
  --small-title-regular-font-weight: 400;
  --small-title-regular-letter-spacing: 0px;
  --small-title-regular-line-height: normal;
  --subheadline-bold-font-family: "SF Pro Text", Helvetica;
  --subheadline-bold-font-size: 15px;
  --subheadline-bold-font-style: normal;
  --subheadline-bold-font-weight: 400;
  --subheadline-bold-letter-spacing: -0.5px;
  --subheadline-bold-line-height: 20px;
  --success: rgba(77, 189, 88, 1);
  --system-backgrounddark-baseprimary: rgba(0, 0, 0, 1);
  --text-150: rgba(250, 250, 250, 1);
  --text-300: rgba(250, 250, 250, 1);
  --text-500: rgba(212, 212, 212, 1);
  --text-600: rgba(152, 152, 152, 1);
  --text-800: rgba(98, 98, 98, 1);
  --text-900: rgba(32, 32, 32, 1);
  --themes-black-100: rgba(28, 28, 28, 1);
  --white: rgba(255, 255, 255, 1);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-themes-mode="pastel-light">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-themes-mode="pastel-light"] {
  --themes-black-100: rgba(28, 28, 28, 1);
}

[data-themes-mode="pastel-dark"] {
  --themes-black-100: rgba(255, 255, 255, 1);
}

[data-themes-mode="bright-light"] {
  --themes-black-100: rgba(46, 52, 62, 1);
}

[data-themes-mode="bright-dark"] {
  --themes-black-100: rgba(255, 255, 255, 1);
}
